import React from "react"
import ReactMarkdown from "react-markdown"
import classNames from "classnames/bind"

import styles from "./guest-artist-card"

const cx = classNames.bind(styles)

// TODO Remove and fix properly one day
const stripHyphens = (slug) =>
  ["ta-coumbaaiken", "aaronrichmond-havel"].includes(slug) ? slug : slug.replace("-", "")

const GuestArtistCard = ({ title, slug, image, content }) => {
  return [
    <a key={1} href={`/artists/${stripHyphens(slug)}`} className={cx("guest-artist-card-image")}>
      <img
        src={`${image.url}`}
        alt="guest portrait"
        srcSet={`${image.url}?w=200 200w, ${image.url}?w=400 400w, ${image.url}?w=600 600w, ${image.url}?w=800 800w`}
        sizes={`(max-width: 767px) 100vw, 30vw`}
      />
    </a>,
    <span key={2} className={cx("guest-artist-card-copy")}>
      <div className={cx("copy")}>
        <h4 className={cx("title")}>
          <a href={`/artists/${stripHyphens(slug)}`}>{title}</a>
        </h4>
        <div className={cx("content")}>
          <ReactMarkdown
            children={content}
            renderers={{
              link: (props) => (
                <a href={props.href} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              ),
            }}
          />
        </div>
      </div>
    </span>,
  ]
}

export default GuestArtistCard
